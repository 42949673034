import { render, staticRenderFns } from "./MonthlyHoroscopeForm.vue?vue&type=template&id=5396e993&"
import script from "./MonthlyHoroscopeForm.vue?vue&type=script&lang=js&"
export * from "./MonthlyHoroscopeForm.vue?vue&type=script&lang=js&"
import style0 from "./MonthlyHoroscopeForm.vue?vue&type=style&index=0&id=5396e993&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_2d82f54f/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5396e993')) {
      api.createRecord('5396e993', component.options)
    } else {
      api.reload('5396e993', component.options)
    }
    module.hot.accept("./MonthlyHoroscopeForm.vue?vue&type=template&id=5396e993&", function () {
      api.rerender('5396e993', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/MonthlyHoroscopeForm.vue"
export default component.exports